.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  gap: 100px;
  width: 100%;
}

.informations {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 600px;
  height: 100%;
}

.informations h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #e5e5e5;
}

.informations h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #e5e5e5;
}

.informations a {
  text-decoration: none;
  color: #e5e5e5;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.1s linear;
}

.chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  background-color: #9ac31c;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s linear;
}

.chat-button:hover {
  background-color: #fff;
  color: #e5e5e5;
  border: 1px solid #9ac31c;
}

@media only screen and (max-width: 720px) {
  .informations {
    width: 300px;
  }
}

.background-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.765);
}

.alert-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  background-color: #404040;
  border-radius: 5px;
  animation-name: maximize;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  position: relative;
  min-height: 250px;
}

.alert-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.alert-content-img {
  width: 100px;
  padding: 5px;
}

.alert-title {
  text-align: center;
  color: rgb(64, 64, 64);
  padding: 5px;
}

.alert-span {
  text-align: center;
  font-size: 15px;
  padding: 2px;
  color: #e5e5e5;
}

.alert-container-button {
  display: flex;
  width: 100%;
}

.alert-container-button-waiting {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: #e74e4e;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  transition: all ease-in 0.2s;
}

.alert-container-button-waiting:hover {
  color: #be3030;
  font-weight: 600;
  transition: all ease-in 0.2s;
}

.alert-success-ok-button {
  flex: 1;
  font-weight: lighter;
  font-size: 15px;
  color: white;
  background-color: #9ac31c;
  border: none;
  padding: 15px;
  transition: all 0.1s linear;
  cursor: pointer;
}

.alert-success-ok-button:hover {
  background-color: #142a4c;
  font-weight: 500;
}

.alert-warning-ok-button {
  flex: 1;
  font-weight: lighter;
  font-size: 15px;
  color: white;
  background-color: #e74e4e;
  border: none;
  padding: 15px;
  transition: all 0.1s linear;
  cursor: pointer;
}

.alert-warning-ok-button:hover {
  background-color: #be3030;
  font-weight: 500;
}

.alert-icon {
  width: 30%;
  height: 30%;
  padding: 10px;
  color: #9ac31c;
}

@keyframes maximize {
  from {
    opacity: 0;
    transform: scale(0%);
  }
  to {
    opacity: 1;
    transform: scale(100%);
  }
}

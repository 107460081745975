.recording-buttons {
  position: absolute;
  top: 6%;
  right: 4.7%;
  z-index: 1000;
  display: flex;
  gap: 10px;
}

.recording-button {
  width: 30px; /* Tamanho do botão */
  height: 30px; /* Igual à largura para ficar redondo */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.stop-recording {
  background: rgb(136, 9, 9);
}

.start-recording {
  background: rgb(10, 129, 10);
}

.recording-button:hover {
  transform: scale(1.1); /* Aumenta levemente o botão */
}

.stop-recording:hover {
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.8); /* Sombra vermelha */
}

.start-recording:hover {
  box-shadow: 0 0 10px rgba(0, 128, 0, 0.8); /* Sombra verde */
}

.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.home-container-sala {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90dvh;
  gap: 100px;
  width: 100%;
}

.container-nova-sala {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 50px;
}

.content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.content-title p {
  font-size: 18px;
  color: #E5e5e5;
  font-weight: 600;
}

.content-title h2 {
  color: #E5e5e5;
  font-weight: 800;
}

.container-nova-sala-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.container-nova-sala-input input {
  display: flex;
  width: 300px;
  height: 60px;
  padding: 20px;
  border: 1px solid #404040;
  border-radius: 5px;
  font-size: 20px;
}

.container-nova-sala-input input:focus {
  border: 2px solid #9ac31c;
  outline: none;
}
.container-nova-sala-input button {
  display: flex;
  width: 100px;
  height: 60px;
  background-color: #9ac31c;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.container-nova-sala-input button:hover {
  background-color: #99c31cdd;
}

/*  modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #404040;
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 80vh;
  border-radius: 10px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.a-link {
  color: #E5e5e5;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
}

.a-link:hover {
  color: #9ac31c;
}

.bs-icon {
  font-size: 25px;
  color: #9ac31c;
  cursor: pointer;
  border: none;
}

.bs-icon-close {
  font-size: 25px;
  color: black;
  font-weight: 800;
  cursor: pointer;
  border: none;
  margin-top: 20px;
}

.bs-icon-close:hover {
  color: #81847a;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: red;
}

.modal-content p {
  font-size: 18px;
  text-align: left;
  margin-top: 20px;
  color: #E5e5e5;
}

.link-sala {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.button-entrar {
  display: flex;
  padding: 10px;
  background-color: #9ac31c;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

/* //customRoom */
.custom-room {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 90dvh;
}

/* a telas menores */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    max-width: none;
    padding: 20px;
    gap: 20px;
  }

  .modal-content h2 {
    font-size: 18px;
    margin-top: 15px;
  }

  .close-button {
    top: 0px;
    right: 10px;
  }

  .link-sala {
    gap: 10px;
    margin-top: 15px;
  }
}

/* telas maiores */
@media (min-width: 1200px) {
  .container-nova-sala-input input {
    width: 400px;
    font-size: 22px;
  }

  .container-nova-sala-input button {
    width: 150px;
    font-size: 22px;
  }

  .modal-content {
    width: 70%;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  .content-title {
    font-size: 20px;
  }
}

.accept-button {
  width: 60px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 4px;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: #404040;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #fff;
  color: #142a4c;
  border: 1px solid #9ac31c;
}

.decline-button {
  margin-top: 10px;
  width: 60px;
  padding: 4px;
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: #9ac31c;
  color: #142a4c;
  font-weight: bold;
  cursor: pointer;
}

.decline-button:hover {
  background-color: red;
  color: #142a4c;
  border: 1px solid red;
}

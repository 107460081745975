@font-face {
  font-family: "SourceCodePro-Regular";
  src: url(../public/fonts/SourceSansPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SourceCodePro-Bold";
  src: url(../public/fonts/SourceSansPro-Bold.otf) format("openType");
}

@font-face {
  font-family: "SourceCodePro-Black";
  src: url(../public/fonts/SourceSansPro-Black.otf) format("opentype");
}

.lk-button > svg{
  color: #9ac31c !important;
}

.lk-track-muted-indicator-microphone > svg{
  color: #9ac31c !important;
}

.lk-join-button{
  background-color: #9ac31c !important;
}

.lk-join-button:hover{
  background-color: #99c31cb6 !important;
}

.lk-connection-quality{
  color: #9ac31c !important;
}


.App {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  min-width: 100dvw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #111;
  font-family: 'SourceCodePro-Regular', sans-serif;
}

.App-logo {
  max-height: 100px;
  margin-left: 20px;
}


.App-header {
  width: 98%;
  background-color: #111;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

/* Estilizar a barra de rolagem */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
  background-color: #878787;
}

/* Estilizar o indicador (o "ponteiro") da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 5px;
}

/* Estilizar a barra de rolagem quando o cursor estiver sobre ela */
::-webkit-scrollbar-thumb:hover {
  background-color: #9ac31c;
  cursor: pointer;
}
